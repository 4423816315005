<template>
  <div>
    <div>
      <v-btn
        class="primary mt-5"
        elevation="2"
        @click="$refs.filePicker.click()"
        :loading="uploading"
      >
        <span>
          Add Photos
        </span>
      </v-btn>

      <input
        ref="filePicker"
        class="d-none"
        type="file"
        multiple
        accept="image/*"
        @change="onFileChange"
      />
    </div>

    <v-row class="mt-4">
      <v-col
        v-for="(image, i) in photos"
        :key="i"
        class="d-flex child-flex"
        cols="4"
      >
        <div class="upload-photos__image">
          <v-btn
            v-if="image.id"
            class="ma-2 upload-photos__close"
            fab
            small
            @click="initDelete(image)"
          >
            <v-icon>{{ icons.close }}</v-icon>
          </v-btn>
          <v-img
            :src="image.url"
            aspect-ratio="1"
            class="grey lighten-2"
            :class="{ 'image-uploading': !image.id }"
          >
          </v-img>
        </div>
      </v-col>
    </v-row>

    <ConfirmModal
      v-model="showDeleteModal"
      title="Delete Photo"
      message="Are you sure you want to delete this photo?"
      @cancel="showDeleteModal = false"
      @confirm="confirmDelete"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Api from '@/services/api'
import { mdiPlus, mdiClose } from '@mdi/js'
import ConfirmModal from '@/components/modals/ConfirmModal'

export default {
  name: 'ProviderPhotos',
  components: {
    ConfirmModal
  },
  data() {
    return {
      icons: {
        plus: mdiPlus,
        close: mdiClose
      },
      uploading: false,
      photos: [],
      selected: null,
      showDeleteModal: false
    }
  },
  computed: {
    ...mapState({
      provider: state => state.provider.details
    })
  },
  mounted() {
    this.fetchPhotos()
  },
  methods: {
    fetchPhotos() {
      Api.get(`/admin/providers/${this.provider.id}/photos`).then(
        response => (this.photos = response.data.data)
      )
    },
    async onFileChange(e) {
      const files = [...e.target.files]

      if (!files.length) return

      this.uploading = true
      const form = new FormData()

      files.map(photo => form.append('photos[]', photo))

      Api.post(`/admin/providers/${this.provider.id}/photos`, form)
        .then(() => this.fetchPhotos())
        .finally(() => (this.uploading = false))
    },
    initDelete(image) {
      this.showDeleteModal = true
      this.selected = image
    },
    confirmDelete() {
      Api.delete(
        `/admin/providers/${this.provider.id}/photos/${this.selected.id}`
      ).then(() => {
        this.fetchPhotos()
        this.showDeleteModal = false
        this.selected = null
      })
    }
  }
}
</script>
